import {
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

export default function OrderItem({
  order,
  vouchers,
}: {
  order: any;
  vouchers: string[];
}) {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget);
  };


  return (
    <Box sx={{ px: { xs: 6, md: 16 }, py: 4  }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
          gap: 4,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
          <Typography sx={{ fontSize: { xs: 20, md: 24 }, fontWeight: 600 }}>
            {order.product.title}
          </Typography>

          <div className="flex flex-col items-start gap-[4px] md:gap-[20px] relative self-stretch w-full flex-[0_0_auto] mt-4">
            <Typography
              sx={{ fontSize: { xs: 12, md: 16 }, display: 'flex', alignItems: 'center', gap: 2 }}
              className="flex flex-col md:flex-row items-start md:items-center gap-[4px] md:gap-[20px]  relative self-stretch w-full flex-[0_0_auto]"
            >
              <Typography
                sx={{
                  border: "1px solid grey",
                  px: 2,
                  py: 1,
                  borderRadius: 2,
                  width: { xs: 160, md: 160 },
                  fontSize: { xs: 12, md: 16 },
                }}
              >
                Tour Date
              </Typography>{" "}
              {order.product.date.slice(0, 10)}
            </Typography>

            {order.product.options && (
              <Typography
                sx={{ fontSize: { xs: 12, md: 16 }, mt: 2, display: 'flex', alignItems: 'center', gap: 2, }}
                className="flex flex-col md:flex-row items-start md:items-center gap-[8px] md:gap-[20px] relative self-stretch w-full flex-[0_0_auto]"
              >
                <Typography
                  sx={{
                    border: "1px solid grey",
                    px: 2,
                    py: 1,
                    borderRadius: 2,
                    width: { xs: 160, md: 160 },
                    fontSize: { xs: 12, md: 16 },
                  }}
                >
                  Options
                </Typography>{" "}
                Transfer only in English
              </Typography>
            )}
            <Typography
              sx={{ fontSize: { xs: 12, md: 16 }, mt: 2, display: 'flex', alignItems: 'center', gap: 2, }}
              className="flex flex-col md:flex-row items-start md:items-center gap-[4px] md:gap-[20px]  relative self-stretch w-full flex-[0_0_auto]"
            >
              <Typography
                sx={{
                  border: "1px solid grey",
                  px: 2,
                  py: 1,
                  borderRadius: 2,
                  width: { xs: 160, md: 160 },
                  fontSize: { xs: 12, md: 16 },
                }}
              >
                Time Slot
              </Typography>{" "}
              {order.product.date.slice(-5)}
            </Typography>

            {order.product?.language && (
              <Typography
                sx={{ fontSize: { xs: 12, md: 16 }, mt: 2, display: 'flex', alignItems: 'center', gap: 2, }}
                className="flex flex-col md:flex-row items-start md:items-center gap-[4px] md:gap-[20px]  relative self-stretch w-full flex-[0_0_auto]"
              >
                <Typography
                  sx={{
                    border: "1px solid grey",
                    px: 2,
                    py: 1,
                    borderRadius: 2,
                    width: { xs: 160, md: 160 },
                    fontSize: { xs: 12, md: 16 },
                  }}
                >
                  Tour Language
                </Typography>
                {order.product.language.name}
              </Typography>
            )}
            <Typography
              sx={{ fontSize: { xs: 12, md: 16 }, mt: 2, display: 'flex', alignItems: 'center', gap: 2 }}
              className="flex flex-col md:flex-row items-start md:items-center gap-[4px] md:gap-[20px]  relative self-stretch w-full flex-[0_0_auto]"
            >
              <Typography
                sx={{
                  border: "1px solid grey",
                  px: 2,
                  py: 1,
                  borderRadius: 2,
                  width: { xs: 160, md: 160 },
                  fontSize: { xs: 12, md: 16 },
                }}
              >
                Tickets
              </Typography>
              {order.product.price_tag.price_feature} -{" "}
              {order.product.price_tag.ticket_holder}
            </Typography>
          </div>
        </Box>
        <Box sx={{ pt: 8 }}>
          <Typography sx={{ fontSize: 20, fontWeight: 600, mb: 2 }}>
            Total:
          </Typography>
          <Typography
            sx={{
              fontSize: 18,
              background: "#3D8C40",
              color: "white",
              px: 8,
              py: 2,
              borderRadius: 1,
              width: "fit-content",
            }}
          >
            {order.product.retail_price.formatted_iso_value}
          </Typography>
          {vouchers.length > 0 ? (
            vouchers.map((voucher: any, index: number) => (
              <Box
                component="iframe"
                src={voucher}
                key={index}
                sx={{ width: "300px", my: 2 }}
              ></Box>
            ))
          ) : (
            <Typography sx={{ fontSize: 16, my: 4 }}>
              No vouchers yet.
            </Typography>
          )}

        </Box>
      </Box>

      {/*{order.extra_customer_data.length > 0 && (
        <>
          <Divider sx={{ mb: 8, mt: 8 }} />
          <Box>
            <Typography sx={{ fontSize: { xs: 14, md: 18 }, fontWeight: 600 }}>
              Extra Customer Data
            </Typography>
            <Box
              sx={{
                width: { xs: "100%", md: "70%" },
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                mt: 6,
                gap: 4,
              }}
            >
              <Box>
                <Typography
                  sx={{ fontSize: { xs: 14, md: 16 }, fontWeight: 500 }}
                >
                  Email Address
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: 11, md: 14 },
                    fontWeight: 500,
                    opacity: 0.7,
                  }}
                >
                  {order.extra_customer_data.email}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{ fontSize: { xs: 14, md: 16 }, fontWeight: 500 }}
                >
                  Phone Number
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: 11, md: 14 },
                    fontWeight: 500,
                    opacity: 0.7,
                  }}
                >
                  {order.extra_customer_data.phone}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{ fontSize: { xs: 14, md: 16 }, fontWeight: 500 }}
                >
                  Nationality
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: 11, md: 14 },
                    fontWeight: 500,
                    opacity: 0.7,
                  }}
                >
                  {order.extra_customer_data.nationality}
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}*/}
      {order.extra_participant_info && (
        <>
          <Divider sx={{ mb: 8, mt: 8 }} />
          <Box sx={{ width: { xs: "100%", md: "50%" } }}>
            <Button
              id="demo-positioned-button"
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{
                background: "white",
                border: "1px solid grey",
                color: "#1c1c1c",
                width: "100%",
                borderRadius: "2px",
                fontSize: { xs: 10, md: 16 },
              }}
            >
              Participant Inormation for Adult Ticket 1
              {open ? (
                <ArrowDropUpOutlined sx={{ position: "absolute", right: 4 }} />
              ) : (
                <ArrowDropDownOutlined
                  sx={{ position: "absolute", right: 4 }}
                />
              )}
            </Button>
            <List
              style={{
                background: "white",
                display: `${open ? "block" : "none"}`,
                border: "1px solid grey",
                borderTop: 0,
                borderRadius: "2px",
              }}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemText primary="Inbox" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemText primary="Drafts" />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </>
      )}
    </Box>
  );
}
