import { useFormik } from "formik";
import { Layout } from "../../../components/layout";
import { Box, Button, FormControl, FormLabel, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material";
import {useEffect, useState} from "react";
import api from "../../../services/api";
import MapPicker from "./MapPicker";
import toast from "react-hot-toast";
import AddOption, {Option} from "./AddOption";
import {useNavigate, useParams} from "react-router-dom";
import {useGetActivityIcons, useGetTour} from "../../../queries/tours";
import EditableDynamicField from "./EditableDynamicField";
import Checkbox from '@mui/material/Checkbox';



type Location = {
	id: number,
	name: string
}

const initialOption = {
	name: "",
	extensions: [{price: 0, name: 'a'}],
	languages: ['EN'],
	times: ['05:00'],
	dates: []
}

export default function EditTour() {
	const {id} = useParams();
	const [tour, setTour] = useState<any>(null);
  const { data, isLoading } = useGetTour(id as string);
	const {data: activityIcons} = useGetActivityIcons();
	const [activities, setActivities] = useState<string[]>([]);
	const [countries, setCountries] = useState<Location[]>([]);
	const [cities, setCities] = useState<Location[]>([]);
	const [options, setOptions] = useState<Option[]>([{} as Option]);
	const navigate = useNavigate();

	const handleActivityChange = (event: any) => {
    const {
      target: { value },
    } = event;
    const icons = typeof value === "string" ? value.split(",") : value;
    setActivities(icons.filter((l: any) => l !== ""));
  };

	useEffect(() => {
    if (!isLoading && data.status === 200) {
			setTour(data?.data?.data.tour);
			const optionss = [...[initialOption], ...data?.data?.data.tour?.options];
			setOptions(optionss || [initialOption] as Option[]);
			setActivities(data?.data?.data.tour.activities.map((activity: any)=>activity.key))
    }
  }, [data, isLoading, id]);

	const formik = useFormik({
		enableReinitialize: true,
    initialValues: {
      title: tour?.title || "" ,
      description: tour?.description || "",
      country: tour?.country?._id || "",
      city: tour?.city?._id || "",
			activities: tour?.activities || [],
      highlights: tour?.highlights || [],
      included: tour?.included || [],
      notIncluded: tour?.notIncluded || [],
      remember: tour?.remember || [],
      cancellationPolicy: tour?.cancellationPolicy || [],
      meetingPoint: tour?.location?.meetingPoint || "",
			options: tour?.options || [initialOption] as Option[],
			lat:tour?.location?.lat ||  32.5,
			long:tour?.location?.long ||  32.5,
			isActive: tour?.isActive !== undefined? tour?.isActive : true
    },
    //validationSchema: schema,
    onSubmit: async (values) => {

			values.options = options.slice(1)
			values.options.map((option: any)=>{
				option.dates = option.dates.map((date: any)=>{
					return new Date(date).toISOString().slice(0,10)
				})
			})
			values.activities = activities
			const res = await api.put(`/admin/tours/${id}/edit`, values);
			if(res.status===200){
				toast("Tour Edited")
				//reset form values
				navigate('/tours/'+res.data.data.tourId)
				formik.resetForm();
			}else{
				toast("Error Editing Tour")
			}
    },
  });

	useEffect(()=>{
		const fillFields = async()=>{
			const countriesRes = await api.get("/admin/countries");
			if(countriesRes.status===200)
				setCountries(countriesRes.data.countries);

		}

		fillFields();
	},[]);

	useEffect(()=>{
		const fillCities = async()=>{
			if(formik.values.country==="")
				return;
			const citiesRes = await api.get("/admin/countries/"+formik.values.country+"/cities");
			if(citiesRes.status===200)
				setCities(citiesRes.data.cities);
		}
		fillCities();
	},[formik.values.country])

	if(!tour)
		return <h2>Loading...</h2>
	return (
    <Layout>
			<>
			<Typography variant="h5" sx={{ mb: 4, px: {xs: 6, sm: 8, md: 16}, pt: 8, fontWeight: 700, fontSize: 32 }}>
				Edit Tour
			</Typography>
      <Box
        component="form"
        noValidate
        onSubmit={formik.handleSubmit}
        sx={{
          px: {xs: 6, sm: 8, md: 16},
          display: "grid",
          gridTemplateColumns: {xs: '1fr', md:"1fr 1fr"},
					rowGap: 4,
					columnGap: 4,
        }}
      >
        <Box>
          <InputLabel htmlFor="title">Title</InputLabel>
          <TextField
            name="title"
            id="title"
						fullWidth
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
						inputProps={{
							style: {
								padding: 10
							}
						}}
          />
        </Box>
        <Box>
          <InputLabel htmlFor="description">Description</InputLabel>
          <TextField
            name="description"
            id="description"
						multiline
						rows={4}
						fullWidth
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
						inputProps={{
							style: {
								padding: 10
							}
						}}
          />
        </Box>

				<FormControl fullWidth>
					<InputLabel id="country">Country</InputLabel>
					<Select
						labelId="country"
						id="country"
						name="country"
						label="Country"
						value={formik.values.country}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						inputProps={{
							style: {
								padding: 8
							}
						}}
					>
						{countries.map((country: Location) => (
							<MenuItem key={country.id} value={country.id}>
								{country.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<FormControl fullWidth>
					<InputLabel id="city">City</InputLabel>
					<Select
						labelId="city"
						id="city"
						name="city"
						label="City"
						disabled={cities.length === 0}
						value={formik.values.city}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						inputProps={{
							style: {
								padding: 8
							}
						}}
					>
						{cities.map((city: Location) => (
							<MenuItem key={city.id} value={city.id}>
								{city.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<Box>
        <InputLabel id="demo-multiple-name-label" sx={{mb: 2}}>Activity Icons</InputLabel>
				<FormControl fullWidth>
              <InputLabel id="demo-multiple-name-label" sx={{mb: 2}}>Activity Icons</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={activities}
                onChange={handleActivityChange}
                input={<OutlinedInput label="Activity" />}
              >
                {activityIcons?.activities?.map((icon: {key: string, name: string}) => (
                  <MenuItem key={icon.key} value={icon.key}>
                    {icon.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
				</Box>

				<Box>
					<EditableDynamicField label='highlights' formik={formik}/>
				</Box>
				<Box>
					<EditableDynamicField label='included' formik={formik}/>
				</Box>
				<Box>
					<EditableDynamicField label='notIncluded' formik={formik}/>
				</Box>
				<Box>
					<EditableDynamicField label='remember' formik={formik}/>
				</Box>
				<Box>
					<EditableDynamicField label='cancellationPolicy' formik={formik}/>
				</Box>

				<Box>
          <InputLabel htmlFor="meetingPoint">Meeting Point</InputLabel>
          <TextField
						sx={{mt: 2}}
            name="meetingPoint"
            id="meetingPoint"
						fullWidth
            value={formik.values.meetingPoint}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
						inputProps={{
							style: {
								padding: 10
							}
						}}
          />
        </Box>


				<Typography sx={{ textAlign: "center", gridColumn: 'span 2', fontSize: 24 }}>Options</Typography>

				{
					options.slice(1).map((_, index) => (
						<AddOption options={options.slice(1)} key={index} index={index} setOptions={setOptions}/>

					))
				}
				<Box sx={{gridColumn: "span 2"}}>
					<InputLabel htmlFor="map">Map</InputLabel>
					<MapPicker formik={formik}/>
				</Box>

				<Box sx={{gridColumn: 'span 2'}}>
			<FormLabel id="demo-row-radio-buttons-group-label">Active</FormLabel>
			<Checkbox
				name='isActive'
				id='isActive'
			  checked={formik.values.isActive}
				onChange={formik.handleChange}
				inputProps={{ 'aria-label': 'controlled' }}
			/>

			</Box>
				<Button
          variant="contained"
          type="submit"
          sx={{ height: 40, width: "100%"}}
        >
          Save Changes
        </Button>
      </Box>
			</>
    </Layout>
  );
}
