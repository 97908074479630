import { useParams } from "react-router-dom";
import { Layout } from "../../../components/layout";
import {useGetOrder} from "../../../queries/orders";
import BPOrderItem from "./BPOrderItem";
import OrderItem from "./OrderItem";

export default function OrderPage() {
  const { id } = useParams();
	const {data: order, isLoading} = useGetOrder(id as string);

	if(isLoading)
		return <h2>Loading..</h2>

	if(!order)
		return <h2>Order not found</h2>
	return (
    <Layout>
			<>
			{order.musementOrder?.items?.map((order: any, index: number)=> <OrderItem order={order} key={index} vouchers={[]}/>)}
    	{order.systemItems?.map((order: any, index: number)=> <BPOrderItem order={order} key={index} vouchers={[]}/>)}

			</>
    </Layout>
  );
}
