import api from "../api";

export async function getTours() {
	const response = await api.get("/admin/tours");
	return response;
}
export async function getTour(id: string) {
	const response = await api.get(`/admin/tours/${id}`);
	return response;
}

export const getActivityIcons = async() => {
  try {
    const response = await api.get(`/activity`);
    return response.data
  } catch (error: any) {
    console.log(error);
    throw error
  }
}