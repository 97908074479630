import { Box, Typography } from "@mui/material";

export default function BPOrderItem({
  order,
  vouchers,
}: {
  order: any;
  vouchers: string[];
}) {
  return (
    <Box sx={{ px: { xs: 6, md: 16 }, py: 4 }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
          gap: 4,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
          <Typography sx={{ fontSize: { xs: 20, md: 24 }, fontWeight: 600 }}>
            {order.tour.title}
          </Typography>

          <div className="flex flex-col items-start gap-[4px] md:gap-[20px] relative self-stretch w-full flex-[0_0_auto] mt-4">
            <Typography
              sx={{ fontSize: { xs: 12, md: 16}, mt: 2, display: 'flex', alignItems: 'center', gap: 2  }}
              className="flex flex-col md:flex-row items-start md:items-center gap-[4px] md:gap-[20px]  relative self-stretch w-full flex-[0_0_auto]"
            >
              <Typography
                sx={{
                  border: "1px solid grey",
                  px: 2,
                  py: 1,
                  borderRadius: 2,
                  width: { xs: 160, md: 160 },
                  fontSize: { xs: 12, md: 16 },
                }}
              >
                Tour Date
              </Typography>{" "}
              {order.date.slice(0, 10)}
            </Typography>

            {order.extensions.map((extension: any, index: number) => {
              return (
                <Typography
                  key={index}
                  sx={{ fontSize: { xs: 12, md: 16 }, mt: 2, display: 'flex', alignItems: 'center', gap: 2  }}
                  className="flex flex-col md:flex-row items-start md:items-center gap-[8px] md:gap-[20px] relative self-stretch w-full flex-[0_0_auto]"
                >
                  <Typography
                    sx={{
                      border: "1px solid grey",
                      px: 2,
                      py: 1,
                      borderRadius: 2,
                      width: { xs: 160, md: 160 },
                      fontSize: { xs: 12, md: 16 },
                    }}
                  >
                    {extension.quantity} x
                  </Typography>{" "}
                  {extension.extension}
                </Typography>
              );
            })}
            <Typography
              sx={{ fontSize: { xs: 12, md: 16 }, mt: 2, display: 'flex', alignItems: 'center', gap: 2  }}
              className="flex flex-col md:flex-row items-start md:items-center gap-[4px] md:gap-[20px]  relative self-stretch w-full flex-[0_0_auto]"
            >
              <Typography
                sx={{
                  border: "1px solid grey",
                  px: 2,
                  py: 1,
                  borderRadius: 2,
                  width: { xs: 160, md: 160 },
                  fontSize: { xs: 12, md: 16 },
                }}
              >
                Time Slot
              </Typography>{" "}
              {order.time}
            </Typography>

            {order.language && (
              <Typography
                sx={{ fontSize: { xs: 12, md: 16 },  mt: 2, display: 'flex', alignItems: 'center', gap: 2  }}
                className="flex flex-col md:flex-row items-start md:items-center gap-[4px] md:gap-[20px]  relative self-stretch w-full flex-[0_0_auto]"
              >
                <Typography
                  sx={{
                    border: "1px solid grey",
                    px: 2,
                    py: 1,
                    borderRadius: 2,
                    width: { xs: 160, md: 160 },
                    fontSize: { xs: 12, md: 16 },
                  }}
                >
                  Tour Language
                </Typography>
                {order.language}
              </Typography>
            )}
            {/*<Typography
              sx={{ fontSize: { xs: 12, md: 16 }, mt: { xs: 2, md: 0 } }}
              className="flex flex-col md:flex-row items-start md:items-center gap-[4px] md:gap-[20px]  relative self-stretch w-full flex-[0_0_auto]"
            >
              <Typography
                sx={{
                  border: "1px solid grey",
                  px: 2,
                  py: 1,
                  borderRadius: 2,
                  width: { xs: 160, md: 160 },
                  fontSize: { xs: 12, md: 16 },
                }}
              >
                <Ticket className="!relative !w-[16px] !h-[16px] text-[color:var(--variable-collection-dark)] inline me-1 md:me-2" />
                Tickets
              </Typography>
              {order.product.price_tag.price_feature} -{" "}
              {order.product.price_tag.ticket_holder}
            </Typography>*/}
          </div>
        </Box>
        <Box sx={{ pt: 8 }}>
          <Typography sx={{ fontSize: 20, fontWeight: 600, mb: 2 }}>
            Total:
          </Typography>
          <Typography
            sx={{
              fontSize: 18,
              background: "#3D8C40",
              color: "white",
              px: 8,
              py: 2,
              borderRadius: 1,
              width: "fit-content",
            }}
          >
            $ {order.price}
          </Typography>
          {vouchers.length > 0 ? (
            vouchers.map((voucher: any, index: number) => (
              <Box
                component="iframe"
                src={voucher}
                key={index}
                sx={{ width: "300px", my: 2 }}
              ></Box>
            ))
          ) : (
            <Typography sx={{ fontSize: 16, my: 4 }}>
              No vouchers yet.
            </Typography>
          )}

        </Box>
      </Box>
    </Box>
  );
}
