import { Box, InputLabel } from "@mui/material";
import {useEffect} from "react";

const EditableDynamicField = ({ label, formik }: { label: string; formik: any }) => {
	useEffect(()=>{
		formik.setFieldValue(label,formik.values[label])
	},[])

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = e.target.value;
    const splitLines = newText.split(/\r?\n/); // Split by new lines
    formik.setFieldValue(label, splitLines.filter((line: any)=>line!==''));
  };

  const labelDisplay =
    label === "cancellationPolicy"
      ? "Cancellation Policy"
      : label === "notIncluded"
      ? "Not Included"
      : label;

  return (
    <Box>
      <InputLabel>
        {labelDisplay.charAt(0).toUpperCase() + labelDisplay.slice(1)}
      </InputLabel>
      <Box
        component='textarea'
				defaultValue={formik.values[label].join('\n')}
        onChange={handleChange}
        rows={6}
        sx={{
          width: '100%',
          resize: 'none',
          borderRadius: 1,
          border: '1px solid rgba(0, 0, 0, 0.23)',
          mt: 2,
          p: 1
        }}
      />

    </Box>
  );
};

export default EditableDynamicField;
