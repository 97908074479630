import { Button } from "@mui/material";
import { useGetTours } from "../../../queries/tours";
import { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

const columns: GridColDef[] = [
  { field: "title", headerName: "Title", width: 500 },
  { field: "country", headerName: "Country", width: 200 },
  { field: "city", headerName: "City", width: 200 },
  //{ field: "price", headerName: "Price", width: 150 },
  {
    field: "view",
    headerName: "",
    width: 100,
    renderCell: (row: any) => (
      <Button onClick={() => (window.location.href = `/tours/${row.row.code}`)}>
        View
      </Button>
    ),
  },
];

export default function ToursTable() {
  const { data, isLoading } = useGetTours();
  const [tours, setTours] = useState<any[]>([]);
  useEffect(() => {
    if (!isLoading && data) setTours(data?.data?.tours);
  }, [data, isLoading]);

  if (isLoading) return <p>Loading...</p>;
  const rows = tours?.map((tour: any) => {
    return {
      _id: tour._id,
      title: tour.title,
      country: tour.country?.name || "",
      city: tour.city?.code || "",
      code: tour.code || "",
      //price: "$" + tour.price,
    };
  });

  return (
    <div style={{ width: "95%", marginTop: "20px" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row._id}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 20]}
      />
    </div>
  );
}