import {useQuery} from "@tanstack/react-query";
import {getActivityIcons, getTour, getTours} from "../services/requests/tours";

export function useGetTours() {
		const {data, isLoading} = useQuery<any,any>({
			queryKey: ["tours"],
			queryFn: ()=>getTours(),
			staleTime: 0,
		})
		return {data, isLoading}
}

export function useGetTour(id: string) {
		const {data, isLoading} = useQuery<any,any>({
			queryKey: ["tours"],
			queryFn: ()=>getTour(id),
			staleTime: 0,
		})
		return {data, isLoading}
}

export function useGetActivityIcons() {
		const {data, isLoading} = useQuery<any,any>({
			queryKey: ["activity-icons"],
			queryFn: ()=>getActivityIcons(),
		})
		return {data, isLoading}
}