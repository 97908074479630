import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import toast from "react-hot-toast";
import { Calendar, DateObject } from "react-multi-date-picker";

const languageList = [
  "English",
  "Spanish",
  "Dutch",
  "German",
  "Arabic",
  "French",
  "Italian",
  "Polish",
  "Portugese",
  "Russian",
];

type Extension = {
  price: number;
  name: string;
};

export type Option = {
  name: string;
  extensions: Extension[];
  languages: string[];
  times: string[];
  dates: string[];
};

export default function AddOption({
  options,
  setOptions,
  index,
}: {
  options: Option[];
  setOptions: any;
  index: number;
}) {
  const [extensions, setExtensions] = useState<Extension[]>([{} as Extension]);
  const [languages, setLanguages] = useState<string[]>([""]);
  const [times, setTimes] = useState<string[]>([""]);
  const [dates, setDates] = useState([[new DateObject()]]);
  const [formattedDates, setFormattedDates] = useState<string[]>([]);
  const [option, setOption] = useState<Option>({ name: "" } as Option);
  const [saved, setSaved] = useState(0);

  useEffect(() => {
    if (!options[index] || Object.keys(options[index]).length === 0) return;
    //const dateObjectsArray = options[index].dates.map(date => new DateObject(date));
    //setDates([dateObjectsArray]);

    setFormattedDates(options[index].dates);
    setOption({ name: options[index].name } as Option);
    setExtensions(options[index].extensions);
    setLanguages(options[index].languages);
    setTimes(options[index].times);
  }, [options, index]);

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    const lang = typeof value === "string" ? value.split(",") : value;
    setLanguages(lang.filter((l: any) => l !== ""));
  };

  useEffect(() => {
    const ranges = [];
    for (let i = 0; i < dates.length; i++) {
      ranges.push(dates[i].map((d: any) => d.format()));
    }
    const allDates: any[] = [];

    ranges.forEach((range) => {
      let startDate = new Date(range[0]);
      let endDate = new Date(range[1]);
      startDate.setDate(startDate.getDate());
      while (startDate <= endDate) {
        allDates.push(startDate.toISOString());
        startDate.setDate(startDate.getDate() + 1);
      }
    });
    setFormattedDates(allDates);
  }, [dates]);

  const handleAddOption = () => {
    const addedOption: any = {
      name: option.name,
      extensions,
      languages,
      times,
    };
    addedOption.extensions = addedOption.extensions.filter(
      (e: any) => e.price && e.name
    );
    addedOption.languages = addedOption.languages.filter((l: any) => l);
    addedOption.times = addedOption.times.filter((t: any) => t);
    addedOption.dates = formattedDates;
    if (
      !addedOption.name ||
      !addedOption.extensions.length ||
      !addedOption.languages.length ||
      !addedOption.times.length ||
      !addedOption.dates.length
    ) {
      toast.error("All fields are required");
      return;
    }
    setSaved(1);
    const newOptions = [{}, ...options];

    setOptions([...newOptions, addedOption]);
  };

  const handleEdit = (index: number)=>{
    //console.log(index)
    //console.log(options[index])
    //console.log(extensions)
    const addedOption: any = {
      name: option.name,
      extensions,
      languages,
      times,
    };
    addedOption.extensions = addedOption.extensions.filter(
      (e: any) => e.price && e.name
    );
    addedOption.languages = addedOption.languages.filter((l: any) => l);
    addedOption.times = addedOption.times.filter((t: any) => t);
    addedOption.dates = formattedDates;
    if (
      !addedOption.name ||
      !addedOption.extensions.length ||
      !addedOption.languages.length ||
      !addedOption.times.length ||
      !addedOption.dates.length
    ) {
      toast.error("All fields are required");
      return;
    }

    setOptions([...options.slice(0,index), addedOption, ...options.slice(index+1)]);
    setSaved(1)
  }

  return (
    <Box sx={{ gridColumn: "span 2" }}>
      {saved===1 ? (
        <Box sx={{ background: "#eee", p: 4 }}>
          <Typography>Name: {option.name}</Typography>
          <Typography>Languages: {languages.join(", ")}</Typography>
          <Typography>Times: {times.join(", ")}</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              maxHeight: 300,
              overflowY: "scroll",
              overflowX: "none",
              gap: 1,
              flexWrap: "wrap",
              my: 2,
            }}
          >
            {formattedDates.map((date: string, index: number) => (
              <Box
                key={index}
                sx={{ display: "flex", background: "white", px: 1, py: "2px" }}
              >
                <Typography
                  component="p"
                  sx={{ fontSize: 12, width: "fit-content" }}
                >
                  {new Date(date).toUTCString().slice(0, 16)}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
            {extensions.map((extension: Extension, index) => (
              <Box key={index} sx={{ background: "#FFF", p: 2 }}>
                <Typography>Extension: {extension.name}</Typography>
                <Typography>Price: {extension.price}</Typography>
              </Box>
            ))}
          </Box>
          <Button
            sx={{
              background: "#1E90FF",
              color: "white",
              height: 40,
              px: 4,
              "&:hover": { background: "#1E90FF" },
              mt: 2
            }}
            onClick={() => setSaved(-1)}
          >
            Edit
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            background: "#eee",
            p: 4,
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
        >
          <Box>
            <InputLabel htmlFor="option">Option</InputLabel>
            <TextField
              sx={{}}
              name="option"
              id="option"
              fullWidth
              value={option.name}
              onChange={(e) => setOption({ ...option, name: e.target.value })}
              inputProps={{
                style: {
                  padding: 10,
                },
              }}
            />
          </Box>

          {extensions.map((extension: Extension, index) => (
            <Box
              key={index}
              sx={{ display: "flex", gap: 4, alignItems: "end" }}
            >
              <Box>
                <InputLabel htmlFor="extension">Extension</InputLabel>
                <TextField
                  name="extension"
                  id="extension"
                  sx={{ width: "500px" }}
                  value={extension.name}
                  onChange={(e) => {
                    setExtensions([
                      ...extensions.slice(0, index),
                      { ...extensions[index], name: e.target.value },
                      ...extensions.slice(index + 1),
                    ])
                    //extensions[index].name = e.target.value;
                  }}
                  fullWidth
                  inputProps={{
                    style: {
                      padding: 10,
                    },
                  }}
                />
              </Box>
              <Box>
                <InputLabel htmlFor="price">Price</InputLabel>
                <TextField
                  name="price"
                  id="price"
                  type="number"
                  value={extension.price}
                  onChange={(e) => {
                    setExtensions([
                      ...extensions.slice(0, index),
                      { ...extensions[index], price: Number(e.target.value) },
                      ...extensions.slice(index + 1),
                    ])
                    //extensions[index].price = Number(e.target.value);
                  }}
                  fullWidth
                  inputProps={{
                    style: {
                      padding: 10,
                    },
                  }}
                />
              </Box>
              <Button
                onClick={() => {
                  if (extension.name !== "" && extension.price > 0) {
                    setExtensions([...extensions, {} as Extension]);
                  }
                }}
                disabled={index !== extensions.length - 1}
                sx={{
                  background: "#1E90FF",
                  color: "white",
                  height: 45,
                  "&:hover": { background: "#1E90FF" },
                }}
              >
                Add
              </Button>
            </Box>
          ))}
          <Box sx={{ display: "flex", gap: 4, flexWrap: "wrap" }}>
            <FormControl sx={{ width: 200 }}>
              <InputLabel id="demo-multiple-name-label">Language</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={languages}
                onChange={handleChange}
                input={<OutlinedInput label="Language" />}
              >
                {languageList.map((lang: string) => (
                  <MenuItem key={lang} value={lang}>
                    {lang}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ display: "flex", gap: 4, flexWrap: "wrap" }}>
            {times.map((time: string, index: number) => (
              <Box key={index}>
                <InputLabel htmlFor="time">Time</InputLabel>
                <TextField
                  name="time"
                  id="time"
                  sx={{ width: "100px" }}
                  value={time}
                  onChange={(e) => {
                    const newdates = [...times];
                    newdates[index] = e.target.value;
                    setTimes(newdates);
                  }}
                  inputProps={{
                    style: {
                      padding: 10,
                    },
                  }}
                />
                <Button
                  onClick={() => {
                    if (time !== "") setTimes([...times, ""]);
                  }}
                  disabled={index !== times.length - 1}
                  sx={{
                    background: "#1E90FF",
                    color: "white",
                    height: 45,
                    marginLeft: 4,
                    "&:hover": { background: "#1E90FF" },
                  }}
                >
                  Add
                </Button>
              </Box>
            ))}
          </Box>
          <Box sx={{ display: "flex", alignItems: "start", gap: 4 }}>
            <Box>
              <InputLabel htmlFor="dates">Dates</InputLabel>
              <Calendar
                format="YYYY-MM-DD"
                value={dates}
                onChange={setDates}
                multiple
                range
                rangeHover
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                maxHeight: 300,
                overflowY: "scroll",
                overflowX: "none",
                gap: 1,
                flexWrap: "wrap",
                mt: 3,
              }}
            >
              {formattedDates.map((date: string, index: number) => (
                <Box
                  key={index}
                  sx={{
                    width: 180,
                    display: "flex",
                    justifyContent: "space-between",
                    background: "white",
                    px: 1,
                    py: "2px",
                  }}
                >
                  <Typography
                    component="p"
                    sx={{ fontSize: 12, width: "fit-content" }}
                  >
                    {new Date(date).toUTCString().slice(0, 16)}
                  </Typography>
                  <Typography
                    component="p"
                    sx={{
                      cursor: "pointer",
                      width: "fit-content",
                      fontSize: 12,
                      color: "red",
                    }}
                    onClick={() =>
                      setFormattedDates(
                        formattedDates.filter(
                          (formattedDate: string) =>
                            formattedDate !== formattedDates[index]
                        )
                      )
                    }
                  >
                    X
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
          {options.length === index && (
            <Button
              sx={{
                background: "#1E90FF",
                color: "white",
                height: 45,
                mt: 2,
                "&:hover": { background: "#1E90FF" },
              }}
              onClick={handleAddOption}
            >
              Save Option
            </Button>
          )}
          {saved === -1 && (
            <Button
              sx={{
                background: "#1E90FF",
                color: "white",
                height: 45,
                mt: 2,
                "&:hover": { background: "#1E90FF" },
              }}
              onClick={() => handleEdit(index)}
            >
              Save Option
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
}
