import {useQuery} from "@tanstack/react-query"
import {getOrder} from "../services/requests/orders"

export const useGetOrder = (id: string) => {

	const { data, isLoading } = useQuery<any, any>({
		queryKey: ["order", id],
		queryFn: () => getOrder(id),
	})
	return {data, isLoading}

}